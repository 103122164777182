import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

const styles = (theme) => ({
	container: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		justifyContent: 'center',
		overflow: 'none',
	},
})

const Callback = (props) => {
	const { t, location, classes } = props
	const hash = location.hash.replace('#', '')
	const parsedHash = hash.split('&').reduce((acc, item) => {
		const parts = item.split('=')
		acc[parts[0]] = parts[1]
		return acc
	}, {})
	useEffect(() => {
		const loadCallbackParams = async () => {
			if (parsedHash.id_token && parsedHash.access_token) {
				props.callbackLoginUser({
					accessToken: parsedHash.access_token,
					idToken: parsedHash.id_token,
					expiresIn: parsedHash.expires_in,
				})
			}
		}
		loadCallbackParams()
	}, [parsedHash])

	return (
		<div className={classes.container}>
			<h1>Logging you in...</h1>
		</div>
	)
}

Callback.propTypes = {
	callbackLoginUser: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation(), withStyles(styles))(Callback)
